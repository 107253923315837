import React from 'react';

import { ContentLayout } from "../layouts/content-layout";
import { DefaultLayout } from "../layouts/default-layout";

import "./index.scss";

export default function HomePage({ children }) {
    return (
        <DefaultLayout title="Shes Mashing It" pageDetails={{ title: "Shes Mashing It" }}>
            <ContentLayout>
                <div className="p-index">
                    <div className="g-grid__container">
                        <div className="g-grid__item">
                            <h1>About Us</h1>
                            <p>MASH Gaming is a diverse, safe and inclusive gaming team that stands up against racism and homophobia in the esports community. We are dedicated to creating an environment that is free from discrimination and hate, and work hard to create a community where everyone can feel welcomed and accepted. We strive to make gaming a fun and enjoyable experience for all, regardless of background. We believe in respect and kindness, and our team values great sportsmanship and friendly competition.</p>
                        </div>
                    </div>
                    <div className="g-grid__container">
                        <div className="g-grid__item">
                            <p>Only the penitent will pass.</p>
                            <form>
                                <label className="u-visually-hidden">
                                    Answer:
                                </label>
                                <input type="text" name="answer" />
                                <input className="u-visually-hidden" type="submit" value="Submit" />
                            </form>
                        </div>
                    </div>
                </div>
            </ContentLayout>
        </DefaultLayout >
    );
};